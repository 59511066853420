'use strict'



export function convertStockJSON(database,stockArr){

 

    database.cars.forEach((e) => stockArr.push(e));
  
  
  }
