{
    "cars": [
      {
        "name": "LIXIANG L7",
        "brand":"LIXIANG",
        "city":"АЛМАТЫ",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img":[ "/data/stock_cars/lil7pro/lil7.png"],
        "type":"последовательный гибрид",
        "nedc": "1100 km",
        "edition":"PRO",
        "engine":"449 л/с",
        "drive":"полный",
        "details":"/data/pdf/li.pdf",
        "phone":"+79818005080",
        "youtube":"https://youtu.be/xbTuJiEHPFM?si=vbPEL_UD72PGADdV"
      },
      {
        "name": "LEOPARD 5",
        "brand":"LEOPARD",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img":[ "data/carimg/leopard5.png"],
        "type":"PHEV",
        "edition":"exploration",
        "engine":"194 + 660 л/с",
        "drive":"полный",
        "details":"/data/pdf/leopard5.pdf",
        "phone":"+79818005080",
        "review":"",
        "fuel": "1.8 л/100км"

      },
      {
        "name": "MERCEDES-BENZ EQE SUV",
        "brand":"MERCEDESBENZ",
        "city":"АЛМАТЫ",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img":[ "data/carimg/mercedes-benzeqesuv.png"],
        "type":"электромобиль",
        "nedc": "609 km",
        "edition":"500 4MATIC",
        "engine":"408 л/с",
        "drive":"полный",
        "details":"/data/pdf/mbeqesuv.pdf",
        "phone":"+79818005080"     
       },
       {
        "name": "HAVAL H5",
        "brand":"HAVAL",
        "city":"АЛМАТЫ",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img":[ "data/carimg/havalh5.png"],
        "type":"бензин",
        "edition":"2.0T AWD",
        "fuel":"8.8 л/100 км",
        "engine":"224 л/с",
        "drive":"полный",
        "details":"/data/pdf/havalh5.pdf",
        "phone":"+79818005080"     
       },
      {
        "name": "POLAR STONE 01",
        "brand":"POLARSTONE",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img":[ "/data/carimg/polarstone01.png"],
        "type":"последовательный гибрид",
        "edition":"7 seats version",
        "engine":"476 л/с",
        "drive":"полный",
        "details":"/data/pdf/polarstone01.pdf",
        "phone":"+79818005080",
        "review":"",
        "nedc": "1115 km"
      },
      {
        "name": "AVATR 11",
        "brand":"AVATR",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img": ["/data/stock_cars/avatr11/avatr11.png"],
        "edition":"116kw AWD 5-seats",
        "type":"электромобиль",
        "drive":"полный",
        "nedc": "700 km",
        "engine":"578 л/с",
        "details":"/data/pdf/avatr11.pdf",
        "phone":"+79818005080"
      },
      {
        "name": "VOYAH FREE 2024",
        "brand":"VOYAH",
        "status":"В НАЛИЧИИ" ,
        "year": "2022",
        "img":[ "/data/carimg/voyahfree2024.png"],
        "type":"последовательный гибрид",
        "nedc": "860 km",
        "edition":"exclusive luxury",
        "engine":"694 л/с",
        "drive":"полный",
        "details":"/data/pdf/voyahfree.pdf",
        "phone":"+79110024444",
        "review":"",
        "youtube":"https://youtu.be/2E20rNHyQSU?si=Hrv7A4GfEZkeWl-T"
      },
      {
        "name": "TANK 400 Hi4-T",
        "brand":"TANK",
        "status":"Цена 'Под Ключ'" ,
        "year": "2023",
        "img":[ "/data/stock_cars/tank400/tank400hi4-t.png"],
        "type":"PHEV",
        "edition":"functional",
        "engine":"415 л/с",
        "drive":"полный",
        "details":"/data/pdf/tank400hi4-t.pdf",
        "phone":"+79818005080",
        "review":"",
        "fuel": "2.6 л/100км"

      }
    ]
}