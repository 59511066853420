'use strict'

let arrNames = []
let carName

let curslide=0
  
const maxslide=document.querySelectorAll('.stockSlider').length - 1

let isFalse=false

export {arrNames,carName,curslide,maxslide,isFalse}